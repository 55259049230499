<template>
  <div class="h100">
    <el-card class="box-card">
      <el-table :data="tableData" border style="width: 100%" size="mini">
        <el-table-column prop="id" label="id" width="100">
        </el-table-column>
        <el-table-column prop="errorFlg" label="状态" width="100">
        </el-table-column>
        <el-table-column prop="request" :show-overflow-tooltip="true" label="request" width="400">
        </el-table-column>
        <el-table-column prop="requestTime" width="160" label="时间"> </el-table-column>
        <el-table-column prop="sysErr" :show-overflow-tooltip="true" label="系统状态"> </el-table-column>
        <el-table-column prop="url"  :show-overflow-tooltip="true" label="url"> </el-table-column>
        <el-table-column prop="response" :show-overflow-tooltip="true" label="response"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="dialogVisible = true;form=scope.row" type="text" size="small"
              >详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="tablePage"> -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-sizes="[10, 200, 300, 400]"
            :page-size="page.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
          >
          </el-pagination>
      <!-- </div> -->
        </el-card>
        <el-dialog title="详情" 
            :visible.sync="dialogVisible"
            @closed="dialogVisible = false"
            >
        <el-form :model="form">
          <el-form-item label="id:">
            <div>{{form.id}}</div>
          </el-form-item>
          <el-form-item label="状态:" >
            <div>{{form.errorFlg}}</div>
          </el-form-item>
          <el-form-item label="request:">
            <div>{{form.request}}</div>
          </el-form-item>
          <el-form-item label="时间:">
            <div>{{form.requestTime}}</div>
          </el-form-item>
          <el-form-item label="系统状态:">
            <div>{{form.sysErr}}</div>
          </el-form-item>
          <el-form-item label="url:">
            <div>{{form.url}}</div>
          </el-form-item>
          <el-form-item label="response:">
            <div>{{form.response}}</div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import {recordlist} from "@/api/api-record/index"
console.log(recordlist)
export default {
  data() {
    return {
      dialogVisible:false,
      form:{
        module:'',
        code:''
      },
      rules: {
          module: [
            { required: true, message: '请输入code', trigger: 'blur' },
          ],
          value: [
            { required: true, message: '请输入value', trigger: 'blur' },
          ]
          },
      page: {
        total: 0,
        currentPage: 1,
        page:1,
        size:10
      },
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
      handleDetail(){
          this.dialogVisible=true;
      },
    getList({page,size}=this.page) {
        console.log(page)
        
      recordlist({current:page,size}).then((res) => {
        this.tableData = res.data.records;
        this.page.total = res.data.total;
        this.page.currentPage = res.data.current;
      });
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.getList();
    },
  },
};
</script>

<style>
</style>